@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  text-decoration: none;
  outline: none;
}

h1 {
  margin: 0;
}

body {
  font: 400 14px Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
}

html,
body,
#root {
  height: 100%;
}

#root {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 1250px) {
  #root {
    width: 90%;
    padding: 0 40px;
  }
}

@media screen and (max-width: 1040px) {
  #root {
    width: 100%;
    padding: 0 40px;
  }
}

@media screen and (max-width: 450px) {
  #root {
    padding: 0 20px;
  }
}
